@font-face {
  font-family: MerriweatherSans-Italic;
  src: url(./fonts/MerriweatherSans-Italic.ttf);
}
@font-face {
  font-family: MerriweatherSans;
  src: url(./fonts/MerriweatherSans.ttf);
}

.App {
  text-align: center;
  background-color:#FFF;
  background-image: url('../src/images/fondo.jpg');
  background-position: top left;
  background-size: 100% auto;
  font-family: MerriweatherSans;
  font-weight: bold;
}

.App-logo {
  height: 3.4em;
  pointer-events: none;
  margin-right: 35px;
  margin-bottom: 60px;
}

.note {
  text-align: right;
}
.note p {
  padding: 0;
  margin: 0;
}
.note .contact{
  margin-top: 30px;
  margin-bottom: 60px;
  font-size: 0.7em;
}
.text-primary{
  color: #00d970;
}
.bg-primary{
  background-color: #00d970;
  color: #FFF;
  padding: 5px;
  position: relative;
  height: 22px;
  font-size: 1em;
  display: inline-block;
  margin-left: 10px;
  font-family: MerriweatherSans-Italic;
  font-weight: bold;
}
.bg-primary:before {
  content: '';
  position: absolute;
  top: 0px;
  left: -10px;
  border-bottom: 32px solid #00d970;
  border-left: 10px solid transparent;
  width: 0;
}
.bg-primary:after {
  content: '';
  position: absolute;
  top: 0;
  right: -10px;
  border-top: 32px solid #00d970;
  border-right: 10px solid transparent;
  width: 0;
}

.social a {
  display: block;
  margin-right: 20px;
  text-decoration: none;
  color: #FFF;
}
.social a img{
  height: 1em;
}

.social .top a{
  margin-bottom: 10px;
}
.social .top a img{
  margin-right: 10px;
}
.social .foot{
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
  font-size: 1.5em;
  color: white;
  margin-right: 20%;
}

@media (prefers-reduced-motion: no-preference) {
  
}

@media only screen and (max-width: 600px) {
  .App {
    background-image: url('../src/images/fondo_movil.jpg');
    background-size: auto 100%;
  }

  .App-logo {
    pointer-events: none;
    margin-bottom: 30px;

  }

  .App-header {
    font-size: 1.2em;
    color: white;
    margin-right: 0%;
    align-items: center;
  }

  .note .contact{
    margin-top: 30px;
    margin-bottom: 60px;
    font-size: 0.7em;
  }

  .social a {
    margin-right: 0;
  }

  .social a img{
    margin-right: 30px;
  }

}



